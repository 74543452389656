import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const workshopData = require("../workshops.json");

function WhatsOn() {
    const [ location ] = useState("bnc");
    const [ currentTime, setCurrentTime ] = useState(Date.now());
    const navigate = useNavigate();

    const navigateToWorkshops = () => {
        navigate("/workshops", { replace: true });
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        setCurrentTime(Date.now());
    }, []);

    return (
        <>
            <div>
                <div className="flex flex-col 2xl:flex-row">
                    <div className="w-full 2xl:w-1/2 flex flex-col">
                        <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                            <h1 className="text-2xl 2xl:text-3xl text-center">What's On</h1>
                            <p className="text-lg 2xl:text-2xl text-center mt-8">
                                To register your interest in any of the sessions listed below, get in touch via <a href="mailto:contact@jaynadanceacademy.co.uk" className="text-sm sm:text-lg 2xl:text-2xl underline">contact@jaynadanceacademy.co.uk</a>
                            </p>
                            <p className="text-lg 2xl:text-2xl text-center mt-8">
                                JDA is taking bookings for personal training, stage choreography, events & more - get in touch for more information.
                            </p>
                            <p className="text-lg 2xl:text-2xl text-center mt-8">
                                JDA t-shirts are now on sale!
                            </p>
                            <p className="text-sm 2xl:text-lg text-center mt-0">
                                (Kids: £11 / Adults: £12)
                            </p>
                        </div>
                        <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                            <h1 className="text-2xl 2xl:text-3xl text-center mb-4">Weekly Schedule</h1>
                            <div className="text-center">
                                <p className="text-2xl mt-4 underline">Tuesday</p>
                                <p className="text-xl mt-0">at Belgrave Neighbourhood Centre</p>
                                <p className="text-lg mt-0"><b>6-6:50pm</b> - Bollywood Kids & Preteens (5-12 yrs)</p>
                                <p className="text-lg mt-0"><b>7-8pm</b> - Bollywood Adults - Group 1 (13+ yrs)</p>
                                <p className="text-2xl mt-4 underline">Wednesday</p>
                                <p className="text-xl mt-0">at Belgrave Neighbourhood Centre</p>
                                <p className="text-lg mt-0"><b>5:45-6:35pm</b> - Hip-Hop basics (13+ yrs)</p>
                                <p className="text-lg mt-0"><b>6:40-7:40pm</b> - BollyFit (13+ yrs)</p>
                                <p className="text-lg mt-0"><b>7:45-8:45pm</b> - Bollywood Adults - Group 2 (13+ yrs)</p>
                            </div>
                            <p className="text-xl text-center mt-8">Block bookings can be made in advance at a reduced cost. All classes also have an option for drop-in sessions, allowing for greater flexibility. Please get in touch with us prior to attending a drop-in session.</p>
                        </div>
                    </div>
                    <div className="w-full 2xl:w-1/2 flex flex-col">
                        {/* <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                            <h1 className="text-2xl 2xl:text-3xl text-center">Click on a location to find out more:</h1>
                            <div className="flex justify-center items-center flex-col md:flex-row">
                                <div className="w-1/2 ml-0 mr-0 lg:ml-8 lg:mr-8 cursor-pointer hover:opacity-60 flex flex-col items-center" onClick={() => { toggleLocation("bnc"); }}>
                                    <img src="/media/venues/bnc.jpg" alt="Belgrave Neighbourhood Centre" className="mt-8 rounded-full w-32 h-32 md:w-48 md:h-48 object-cover"></img>
                                    <p className={`text-md lg:text-lg text-center mt-4 ${location === "bnc" && "underline"}`}>Belgrave Neighbourhood Centre</p>
                                </div>
                            </div>
                            <div className={`${location === undefined && "hidden"} text-center mt-8`}>
                                <p className="text-xl">See below for this location's class schedule.</p>
                            </div>
                        </div> */}
                        { location === "bnc" && <BelgraveNeighbourhoodCentre /> }
                        <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                            <h1 className="text-3xl text-center">Upcoming Workshops</h1>
                            <div className="mt-4 flex flex-col gap-4">
                                { workshopData.filter(w => w.timestampMs >= currentTime).sort((a, b) => a.timestampMs - b.timestampMs).map(w =>
                                    <div className="flex flex-col gap-4 md:flex-row justify-start text-center items-center md:items-start md:text-left">
                                        <img alt={w.imgAlt} src={w.imgSrc} className="mt-2 mb-4 md:mb-0 w-1/2 max-w-60 min-w-60 h-1/2 rounded-lg shadow-lg" />
                                        <div>
                                            <h3 className="text-2xl 2xl:text-3xl">{w.title}</h3>
                                            <h4 className="text-xl 2xl:text-2xl">{w.location}</h4>
                                            <h4 className="text-lg 2xl:text-xl">{w.date}</h4>
                                            <h4 className="text-md 2xl:text-xl" dangerouslySetInnerHTML={{__html: w.time}} />
                                            <h4 className="text-sm 2xl:text-lg">{w.price}</h4>
                                        </div>
                                    </div>
                                )}
                                { !workshopData.filter(w => w.timestampMs >= currentTime).length &&
                                    <p className="text-2xl text-center">Keep an eye out!</p>
                                }
                                <p onClick={navigateToWorkshops} className="text-2xl text-center hover:cursor-pointer underline">See more...</p>
                            </div>
                        </div>
                        <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                            <h1 className="text-3xl text-center">Offers this block</h1>
                            <div className="justify-center items-start mt-8">
                                <p className="text-xl text-center mt-8"><b>Introduce a friend! (for 10% off)</b></p>
                                <p className="text-lg text-center mt-0">10% off for you and a friend when you sign up to the full block by 6th January<br />(at least one student must be a new member to be eligible for discount)</p>
                                <p className="text-xl text-center mt-8"><b>Double Deal!</b></p>
                                <p className="text-lg text-center mt-0">Gain access to both Bollywood Adults classes for just £190 for the block!</p>
                                <p className="text-xl text-center mt-8"><b>All In!</b></p>
                                <p className="text-lg text-center mt-0">Want to join all of our adult classes? Get in touch - we have a special deal for you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function BelgraveNeighbourhoodCentre() {
    return (
        <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
            <h1 className="text-3xl text-center">Belgrave Neighbourhood Centre</h1>
            <h2 className="text-xl text-center">Rothley St, Leicester, LE4 6LF</h2>
            
            <div className="flex flex-col md:flex-row mt-8">
                <div className="w-full md:w-3/5 text-left">
                    <p className="text-xl"><b>Bollywood Kids & Preteens</b></p>
                    <p className="text-md underline">£104 (£8 per class) for Spring block or £9 drop-in price - 13 classes</p>
                    <p className="text-md">Weekly Bollywood dance sessions to your favourite film songs! These classes will explore the different genres of Hindi music through fun and upbeat choreography.</p> 
                </div>
                <div className="w-full md:w-2/5 text-left mt-4 md:mt-0 md:text-right">
                    <p className="text-lg"><b>Spring block dates</b> <div className="inline text-sm">(Tue)</div></p>
                    <p className="text-md"><b>Jan:</b> 7th, 14th, 21st, 28th</p>
                    <p className="text-md"><b>Feb:</b> 4th, 11th, 25th</p>
                    <p className="text-md"><b>Mar:</b> 4th, 11th, 18th</p>
                    <p className="text-md"><b>Apr:</b> 1st, 8th, 15th</p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mt-8">
                <div className="w-full md:w-3/5 text-left">
                    <p className="text-xl"><b>Bollywood Adults - Group 1 (Tuesdays)</b></p>
                    <p className="text-md underline">£104 (£8 per class) for Spring block or £9 drop-in price - 13 classes</p>
                    <p className="text-md">Weekly Bollywood dance sessions to your favourite film songs! These classes will explore the different genres of Hindi music through fun and upbeat choreography.</p> 
                </div>
                <div className="w-full md:w-2/5 text-left mt-4 md:mt-0 md:text-right">
                    <p className="text-lg"><b>Spring block dates</b> <div className="inline text-sm">(Tue)</div></p>
                    <p className="text-md"><b>Jan:</b> 7th, 14th, 21st, 28th</p>
                    <p className="text-md"><b>Feb:</b> 4th, 11th, 25th</p>
                    <p className="text-md"><b>Mar:</b> 4th, 11th, 18th</p>
                    <p className="text-md"><b>Apr:</b> 1st, 8th, 15th</p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mt-8">
                <div className="w-full md:w-3/5 text-left">
                    <p className="text-xl"><b>Bollywood Adults - Group 2 (Wednesdays)</b></p>
                    <p className="text-md underline">£104 (£8 per class) for Spring block or £9 drop-in price - 13 classes</p>
                    <p className="text-md">Weekly Bollywood dance sessions to your favourite film songs! These classes will explore the different genres of Hindi music through fun and upbeat choreography.</p> 
                </div>
                <div className="w-full md:w-2/5 text-left mt-4 md:mt-0 md:text-right">
                    <p className="text-lg"><b>Spring block dates</b> <div className="inline text-sm">(Wed)</div></p>
                    <p className="text-md"><b>Jan:</b> 8th, 15th, 22nd, 29th</p>
                    <p className="text-md"><b>Feb:</b> 5th, 12th, 26th</p>
                    <p className="text-md"><b>Mar:</b> 5th, 12th, 19th, 26th</p>
                    <p className="text-md"><b>Apr:</b> 2nd, 9th</p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mt-8">
                <div className="w-full md:w-3/5 text-left">
                    <p className="text-xl"><b>Hip-Hop basics</b></p>
                    <p className="text-md underline">£104 (£8 per class) for Spring block or £9 drop-in price - 13 classes</p>
                    <p className="text-md">This session will allow you to expand your knowledge on the fundamental techniques of Hip-Hop, as you become more familiar with movements, drills and flow.</p> 
                </div>
                <div className="w-full md:w-2/5 text-left mt-4 md:mt-0 md:text-right">
                    <p className="text-lg"><b>Spring block dates</b> <div className="inline text-sm">(Wed)</div></p>
                    <p className="text-md"><b>Jan:</b> 8th, 15th, 22nd, 29th</p>
                    <p className="text-md"><b>Feb:</b> 5th, 12th, 26th</p>
                    <p className="text-md"><b>Mar:</b> 5th, 12th, 19th, 26th</p>
                    <p className="text-md"><b>Apr:</b> 2nd, 9th</p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mt-8">
                <div className="w-full md:w-3/5 text-left">
                    <p className="text-xl"><b>BollyFit</b></p>
                    <p className="text-md underline">£65 (£5 per class) for Spring block or £6 drop-in price - 13 classes</p>
                    <p className="text-md">Each BollyFit class will consist of different Bollywood songs across multiple decades of films. This class will allow you to get fit whilst having fun, and will unleash the Bollywood actor/actress inside you!</p> 
                </div>
                <div className="w-full md:w-2/5 text-left mt-4 md:mt-0 md:text-right">
                    <p className="text-lg"><b>Spring block dates</b> <div className="inline text-sm">(Wed)</div></p>
                    <p className="text-md"><b>Jan:</b> 8th, 15th, 22nd, 29th</p>
                    <p className="text-md"><b>Feb:</b> 5th, 12th, 26th</p>
                    <p className="text-md"><b>Mar:</b> 5th, 12th, 19th, 26th</p>
                    <p className="text-md"><b>Apr:</b> 2nd, 9th</p>
                </div>
            </div>
        </div>
    )
}

export default WhatsOn;