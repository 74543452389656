import { useEffect, useState } from "react";

const workshopData = require("../workshops.json");

function Workshops() {
    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        setCurrentTime(Date.now());
    }, []);

    return (
        <>
            <div>
                <div className="flex flex-col">
                    <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                        <h1 className="text-3xl text-center">Upcoming Workshops</h1>
                        <div className="mt-4 flex flex-col gap-4">
                            { workshopData.filter(w => w.timestampMs >= currentTime).sort((a, b) => a.timestampMs - b.timestampMs).map(w =>
                                <div className="flex flex-col gap-4 md:flex-row justify-start text-center items-center md:items-start md:text-left">
                                    <img alt={w.imgAlt} src={w.imgSrc} className="mt-2 mb-4 md:mb-0 w-1/2 max-w-60 min-w-60 h-1/2 rounded-lg shadow-lg" />
                                    <div>
                                        <h3 className="text-2xl 2xl:text-3xl">{w.title}</h3>
                                        <h4 className="text-xl 2xl:text-2xl">{w.location}</h4>
                                        <h4 className="text-lg 2xl:text-xl">{w.date}</h4>
                                        <h4 className="text-md 2xl:text-xl" dangerouslySetInnerHTML={{__html: w.time}} />
                                        <h4 className="text-sm 2xl:text-lg">{w.price}</h4>
                                    </div>
                                </div>
                            )}
                            { !workshopData.filter(w => w.timestampMs >= currentTime).length &&
                                <p className="text-2xl text-center">Keep an eye out!</p>
                            }
                        </div>
                    </div>
                    <div className="bg-white shadow-inner p-10 m-4 mt-8 mb-0 rounded-2xl">
                        <h1 className="text-3xl text-center">Previous Workshops</h1>
                        <div className="mt-4 flex flex-col gap-4">
                            { Array.from(new Set(workshopData.map(w => new Date(w.timestampMs).getFullYear()))).sort((a, b) => b - a).map((year) =>
                                <>
                                    <h2 className="text-2xl text-left mt-4">{year}</h2>
                                    { workshopData.filter(w => w.timestampMs < currentTime && new Date(w.timestampMs).getFullYear() === year).sort((a, b) => b.timestampMs - a.timestampMs).map(w =>
                                        <div className="flex flex-col gap-4 md:flex-row justify-start text-center items-center md:items-start md:text-left">
                                            <img alt={w.imgAlt} src={w.imgSrc} className="mt-2 mb-4 md:mb-0 w-1/2 max-w-60 min-w-60 h-1/2 rounded-lg shadow-lg" />
                                            <div>
                                                <h3 className="text-2xl 2xl:text-3xl">{w.title}</h3>
                                                <h4 className="text-xl 2xl:text-2xl">{w.location}</h4>
                                                <h4 className="text-lg 2xl:text-xl">{w.date}</h4>
                                                <h4 className="text-md 2xl:text-xl" dangerouslySetInnerHTML={{__html: w.time}} />
                                                <h4 className="text-sm 2xl:text-lg">{w.price}</h4>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {/* <p className="text-2xl text-center">Keep an eye out!</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Workshops;